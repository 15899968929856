<template>
  <section class="user-form-section">
    <v-card  >
      <v-card-title>Créer un Compte Utilisateur</v-card-title>
      <v-form>
        <div class="px-3">
          <v-card-text class="pt-5">
            
            <v-row>
              <v-col cols="12"  md="12">
                <v-text-field
                  v-model="lastname"
                  label="Nom"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="firstname"
                  label="Prénom"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  label="E-mail"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="numTel"
                  label="Numéro de téléphone"
                  outlined
                  type="tel"
                  hint="Entrez votre numéro de téléphone"
                ></v-text-field>

                <v-text-field
                  v-model="newPassword"
                  :type="showPassword ? 'text' : 'password'"
                  label="Mot de passe"
                  outlined
                  hint="Entrez un mot de passe"
                  :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append="togglePasswordVisibility"
                ></v-text-field>

                <v-text-field
                  v-model="cPassword"
                  :type="showPassword ? 'text' : 'password'"
                  label="Confirmer le mot de passe"
                  outlined
                  hint="Confirmez le mot de passe"
                  :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append="togglePasswordVisibility"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-btn
              @click="verifyPasswordAndEmail"
              color="info"
              class="me-3 mt-3"
              style="background-color: #A8FE39;  margin-left: 120px; width: 150px;"
            >
              Ajouter 
            </v-btn>
            <v-btn
              @click="resetForm"
              color="secondary"
              outlined
              class="mt-3"
              style="width: 150px;"
            >
              Reset
            </v-btn>
          </v-card-text>
        </div>
      </v-form>
    </v-card>
    
    <!-- divider -->
    <v-divider></v-divider>
    
    <!-- User Table -->
    <v-card>
      <v-card-title>Liste des Utilisateurs</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        :server-items-length="totalUsers"
        :loading="loading"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        class="elevation-1"
        @update:page="page => fetchUsers(page)"
      >
      </v-data-table>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="dialogVisible" max-width="600">
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar v-if="!valid" color="error" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAlertCircleOutline }}
              </v-icon>
            </v-avatar>
            <v-avatar v-if="valid" color="success" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAccountCheck }}
              </v-icon>
            </v-avatar>
          </v-card-text>
          <v-card-text>
            <h3 style="color: black;">{{ passwordError }}</h3>
          </v-card-text>
          <v-card-text>
            <v-btn
              v-if="!valid"
              @click="closeDialog"
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color:#0B0B0B"
            >
              fermer
            </v-btn>
            <v-btn
              v-if="valid"
              @click="updateUser"
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color:#0B0B0B"
            >
              Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>

<script>
import {
  mdiKeyOutline,
  mdiLockOpenOutline,
  mdiAlertCircleOutline,
  mdiAccountCheck,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import firebase from "firebase/compat/app";
import "firebase/auth";
import Constant from "@/utils/constants";
import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      filteredUsers: [],
      showPassword: false,
      headers: [
        { text: "Prénom", value: "firstName" },
        { text: "Nom", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Numéro de téléphone", value: "numTel" },
      ],
      loading: false,
      search: '',
      totalUsers: 0,
      itemsPerPage: 5,
      currentPage: 1,
      user: {
        firstname: "",
        lastname: "",
        email: "",
        role: "LIBRARY",
        numTel: "",
      },
      valid: false,
      email: "",
      lastname: "",
      firstname: "",
      numTel: "",
      newPassword: "",
      cPassword: "",
      passwordError: "",
      dialogVisible: false,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiAlertCircleOutline,
        mdiAccountCheck,
      },
    };
  },
  methods: {
    async fetchUsers(page) {
      if (isNaN(page) || page <= 0) {
        console.error("Invalid page number:", page);
        return;
      }
      this.loading = true;
      try {
        const response = await axios.get(Constant.QORANI + `user/getByRole/${this.user.role}/${page - 1}?itemsPerPage=${this.itemsPerPage}`);
        this.users = response.data.userList;
        this.filteredUsers = this.users;
        this.totalUsers = response.data.totalNbrElement;
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        this.loading = false;
      }
    },
    async updateUser() {
      const createdBy = firebase.auth().currentUser;
      if (!createdBy) {
        console.error("No user is currently logged in.");
        return;
      }

      const createdByMail = createdBy.email;
      
      try {
        const auth = firebase.auth();
        await auth.createUserWithEmailAndPassword(this.email, this.newPassword);

        const userData = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          numTel: this.numTel,
          role: "LIBRARY",
          createdBy: createdByMail,
        };

        const response = await fetch(Constant.QORANI + "user/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }

        this.passwordError = "";
        this.dialogVisible = false;
        alert("Account created successfully!");
        window.location.reload();
      } catch (error) {
        this.passwordError = error.message;
        this.showDialog();
      }
    },
    verifyPasswordAndEmail() {
      const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const minLength = 8;
      const uppercaseRegex = /[A-Z]/;
      const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-="']/;
      const digitRegex = /\d/;

      if (!this.email) {
        this.passwordError = "E-mail est requis.";
        this.valid = false;
        this.showDialog();
      } else if (!emailFormatRegex.test(this.email)) {
        this.passwordError = "Format e-mail invalide.";
        this.valid = false;
        this.showDialog();
      } else if (!this.newPassword) {
        this.passwordError = "Mot de passe requis.";
        this.valid = false;
        this.showDialog();
      } else if (this.newPassword.length < minLength) {
        this.passwordError = "Le mot de passe doit comporter au moins 8 caractères.";
        this.valid = false;
        this.showDialog();
      } else if (!uppercaseRegex.test(this.newPassword)) {
        this.passwordError = "Le mot de passe doit contenir au moins une lettre majuscule.";
        this.valid = false;
        this.showDialog();
      } else if (
        !(
          specialCharsRegex.test(this.newPassword) ||
          digitRegex.test(this.newPassword)
        )
      ) {
        this.passwordError = "Le mot de passe doit contenir au moins un chiffre, un caractère spécial ou un espace.";
        this.valid = false;
        this.showDialog();
      } else if (this.newPassword !== this.cPassword) {
        this.passwordError = "Les mots de passe ne correspondent pas.";
        this.valid = false;
        this.showDialog();
      } else {
        this.passwordError = "Le mot de passe et email sont valides !";
        this.valid = true;
        this.updateUser();
      }
    },
    showDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    resetForm() {
      this.lastname = "";
      this.firstname = "";
      this.email = "";
      this.numTel = "";
      this.newPassword = "";
      this.cPassword = "";
      this.passwordError = "";
      this.valid = false;
      this.dialogVisible = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {
    currentPage(newPage) {
      this.fetchUsers(newPage);
    }
  },
  mounted() {
    this.fetchUsers(this.currentPage);
  },
};
</script>

<style lang="scss" scoped>
/* Base styles for the user form section */
.user-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

/* Card styling for the form */
.v-card {
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* White background for cards */
}

/* Input field styling */
.v-text-field {
  margin-bottom: 16px; /* Spacing between fields */
}

/* Button styling */
.v-btn {
  border-radius: 6px; /* Rounded corners for buttons */
  font-weight: 500; /* Font weight for buttons */
  transition: background-color 0.3s, transform 0.3s; /* Transition for hover effects */
}

.v-btn:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Divider styling */
.v-divider {
  margin: 24px 0; /* Spacing for dividers */
}

/* Data table styling */
.elevation-1 {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for data table */
  border-radius: 12px; /* Rounded corners */
}

/* Avatar styling */
.v-avatar {
  border-radius: 50%; /* Circular avatar */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .user-form-section {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-card {
    margin-bottom: 15px; /* Adjusted margin for cards */
  }

  .v-btn {
    width: 100%; /* Full width buttons on small screens */
    margin-top: 10px; /* Spacing between buttons */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables */
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 100%; /* Allow full width on larger screens */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for larger screens */
  }
}

/* Dialog styling */
.v-dialog .v-card {
  border-radius: 12px; /* Rounded corners for dialog */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for dialog */
}

.v-card-title {
  font-weight: 500; /* Bold title for dialog */
}

.v-card-text {
  text-align: center; /* Center text in card */
}

/* Special hover effects for icons */
.icon-edit:hover {
  color: #1976d2; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.icon-delete:hover {
  color: #d32f2f; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.icon-view:hover {
  color: #388e3c; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}
</style>
